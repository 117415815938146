import Swiper from "swiper/bundle";

$(".-gallery").each(function () {
  var gallery = new Swiper("." + $(this).attr("data-id"), {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
});
